import './index.sass'
import React from 'react'
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const Contacts = () => {
    let history = useHistory();
    let { t } = useTranslation();

    return (
        <div className='contacts page'>
            <div className='contacts-container'>
                <div className='linkers'>
                    <ul>
                        <li><a href={'./'}>Главная</a></li>
                        <li><a onClick={() => history.goBack()}>Назад</a></li>
                        <li><span>Контакты</span></li>
                    </ul>
                </div>
                <h1>Контакты</h1>
                <div className="tabs__content">
                    <div className="shops__item  shops__item--online">
                        <div className="shops__name">
                            Интернет-магазин
                        </div>
                        <div className="shops__descr">
                            <p>
                                <span>Телефон</span>
                                <span><a href='tel:+7(911)781-31-00'>+7 (911) 781 31 00</a></span>
                            </p>
                            <p>
                                <span>Email</span>
                                <span><a href='mailto: Happestar@mail.ru'>Happestar@mail.ru</a></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export { Contacts }
